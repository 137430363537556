<template>
  <LayoutAuthForm id="divSmsVerificationWrapper">
    <VueForm ref="form" :bodyPadding="['0', '20']">
      <VueContentHolder :padding="[10, 30, 0, 30]">
        <VueHeadline class="phone-number" level="1"
          >+90 {{ this.formModel.phoneNumber }}</VueHeadline
        >
      </VueContentHolder>
      <VueContentHolder :padding="[15, 30, 40, 30]">
        <VueText class="verification-code-info" sizeLevel="3"
          >Numaralı telefona gönderilen doğrulama kodunu aşağıdaki alana yazınız.</VueText
        >
      </VueContentHolder>
      <VueInput
        name="verificationCode"
        id="verificationCode"
        type="password"
        validatorName="SMS Kodu"
        label="SMS Kodu"
        v-model="formModel.verificationCode"
        :messageOnTop="true"
        borderType="left-bordered"
        validationRules="required|numeric|max:7|min:6"
      />
      <VueSpacer level="30" />

      <div class="sms-button-wrapper">
        <div
          v-if="timer.countdown > 0"
          :class="[
            isRepeatButtonDisabled ? 'disabled-sms-button-countdown' : 'sms-button-countdown',
          ]"
        >
          {{ timer.countdown }}
        </div>
        <BrandButton
          :outlined="true"
          :size="sizes.xSmall"
          :disabled="isRepeatButtonDisabled"
          :class="[isRepeatButtonDisabled ? 'brand-repeat-button-disabled' : 'brand-repeat-button']"
          @click="sendSmsCodeAgain()"
        >
          <VueText sizeLevel="4" weightLevel="4">Tekrar Gönder</VueText>
        </BrandButton>
      </div>
      <div slot="footer">
        <BrandButton
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          :padding="{ vertical: 15, horizontal: 0 }"
          @click.prevent="handleSubmit"
          >DEVAM ET</BrandButton
        >
      </div>
    </VueForm>
  </LayoutAuthForm>
</template>

<script>
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import LayoutAuthForm from '@/views/layouts/LayoutAuthForm.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import { Account } from '@/services/Api/index';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'SmsVerification',
  components: {
    LayoutAuthForm,
    VueHeadline,
    VueSpacer,
    VueForm,
    VueInput,
    BrandButton,
    VueText,
    VueContentHolder,
  },
  data() {
    let smsCounter = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_FIRST_LOGIN_SMS_COUNTER,
    }).get();
    const counterConfig = 180;
    let counter = counterConfig;
    let startCountDownImmediate = true;
    let repeatDisabled = true;
    if (!smsCounter) {
      startCountDownImmediate = false;
      repeatDisabled = false;
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_FIRST_LOGIN_SMS_COUNTER }).set({
        date: new Date(),
        isRefresh: true,
      });
    } else {
      if (smsCounter.isRefresh && smsCounter.date) {
        let substraction = Math.round(
          (new Date().getTime() - new Date(smsCounter.date).getTime()) / 1000,
        );
        if (substraction >= counterConfig) {
          startCountDownImmediate = false;
          repeatDisabled = false;
        } else {
          counter = counterConfig - substraction;
        }
      } else {
        smsCounter.isRefresh = true;
        new StorageHelper({
          id: process.env.VUE_APP_STORAGE_FIRST_LOGIN_SMS_COUNTER,
        }).set(smsCounter);
      }
    }
    return {
      formModel: {
        verificationCode: '',
        phoneNumber: '',
      },
      startCountDownImmediate: startCountDownImmediate,
      submitted: false,
      isRepeatButtonDisabled: repeatDisabled,
      timer: { set: counter, countdown: counter, reset: counterConfig },
      intervalID: '',
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    routes() {
      return {
        auth: RoutesAthentication,
      };
    },
  },
  mounted() {
    const phoneNumberFromStorage = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_CURRENT_USER_PN_KEY,
    }).get();
    this.formModel.phoneNumber = phoneNumberFromStorage;
    if (this.startCountDownImmediate) {
      this.startCountDown();
    }
  },
  destroyed() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  },
  watch: {
    'timer.countdown': {
      handler: function(time) {
        if (time <= 0) {
          clearInterval(this.intervalID);
          this.isRepeatButtonDisabled = false;
          this.timer.set = this.timer.reset;
        }
      },
    },
  },
  methods: {
    startCountDown() {
      if (this.intervalID) {
        clearInterval(this.intervalID);
      }
      this.timer.countdown = this.timer.set;
      this.isRepeatButtonDisabled = true;
      this.intervalID = setInterval(() => {
        this.timer.countdown -= 1;
      }, 1000);
    },

    registerSmsCodeToStorage() {
      new StorageHelper({
        id: process.env.VUE_APP_STORAGE_CURRENT_SMS_KEY,
      }).set(this.formModel.verificationCode);
    },
    sendSmsCodeAgain() {
      Account.sendSmsForChangePassword(this.formModel.phoneNumber).then(res => {
        if (res.data.Data) {
          new StorageHelper({ id: process.env.VUE_APP_STORAGE_FIRST_LOGIN_SMS_COUNTER }).set({
            date: new Date(),
            isRefresh: true,
          });
          this.startCountDown();
        }
      });
    },
    async handleSubmit() {
      this.submitted = true;
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        Account.sendSmsCodeWithPhoneNumber(
          this.formModel.verificationCode,
          this.formModel.phoneNumber,
        ).then(res => {
          if (res.data.Data) {
            this.registerSmsCodeToStorage();
            const { Data } = res ? res.data : { Data: null };
            if (Data && Data.success) {
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_PN_KEY }).set(
                this.formModel.phoneNumber,
              );
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).set(Data.token);
            }
            this.$store.dispatch('auth/setAuthData', Data);
            this.$router.push('/auth/login/new-password');
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-number,
.verification-code-info {
  text-align: center;
}
.sms-button-wrapper {
  width: 140px;
  margin: auto;
  position: relative;
}
.disabled-sms-button-countdown {
  border: 1px solid palette-color-level('white', '100');
}
.sms-button-countdown {
  border: 1px solid palette-color-level('yellow', '30');
}
.sms-button-countdown,
.disabled-sms-button-countdown {
  border-radius: palette-radius-level('0');
  position: absolute;
  width: 35px;
  height: 35px;
  z-index: 50;
  text-align: center;
  padding-top: palette-space-level('8');
  margin-bottom: palette-space-level('2');
}
.brand-repeat-button {
  justify-content: center;
  padding: palette-padding-level(10, 20);
  &-disabled {
    padding: unset;
    padding-right: palette-space-level('8');
    justify-content: flex-end;
  }
}
</style>
